import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

function Root() {
  const location = useLocation();

  return (
    <>
      <div className="page-wrapper">
        {location.pathname === "/gallery" ? null : <Header />}
        <Outlet />
        <ScrollRestoration />
        {location.pathname === "/gallery" ? null : (
          <div style={{ position: "relative", zIndex: 2 }}>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
}

export default Root;
