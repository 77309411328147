import {lazy} from 'react'
import { createBrowserRouter } from "react-router-dom";
import Root from "../pages/Root";
// import Homepage from "../pages/Homepage";
// import Residences from "../pages/InteriorsFeatures";
// import Floorplans from "../pages/Floorplans";
// import FloorplansDetail from '../pages/FloorplansDetail'
// import LocationLifestyle from "../pages/LocationLifestyle";
// import Gallery from "../pages/Gallery";
// import Team from "../pages/Team";
// import Register from "../pages/Register";
// import PrivacyPolicy from "../pages/PrivacyPolicy"
// import Thankyou from "../pages/Thankyou"

const Home = lazy(()=> import ('../pages/Homepage'))
const ResidencesPage = lazy(()=> import ('../pages/InteriorsFeatures'))
const FloorplansPage = lazy(()=> import ('../pages/Floorplans'))
const FloorplansDetailPage = lazy(()=> import ('../pages/FloorplansDetail'))
const LocationLifestylePage = lazy(()=> import ('../pages/LocationLifestyle'))
const GalleryPage = lazy(()=> import ('../pages/Gallery'))
const TeamPage = lazy(()=> import ('../pages/Team'))
const RegisterPage = lazy(()=> import ('../pages/Register'))
const PrivacyPolicyPage = lazy(()=> import ('../pages/PrivacyPolicy'))
const ThankyouPage = lazy(()=> import ('../pages/Thankyou'))
const RealtorKit = lazy(()=> import ('../pages/RealtorKit'))

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/interiors-features",
        element: <ResidencesPage />,
      },
      {
        path: "/floorplans",
        element: <FloorplansPage />,
      },
      {
        path: "/floorplansDetail/:id",
        element: <FloorplansDetailPage />,
      },
      {
        path: "/location-lifestyle",
        element: <LocationLifestylePage />,
      },
      {
        path: "/gallery",
        element: <GalleryPage />,
      },
      {
        path: "/team",
        element: <TeamPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/thankyou",
        element: <ThankyouPage />,
      },
      {
        path: "/privacyPolicy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/realtor-kit",
        element: <RealtorKit />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export default router;
